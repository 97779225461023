import profile from "../assets/images/profile/profile2.jpg";
export interface Props{
  setTabs?: any;
  active : Number;
}
const HomeSection = ({setTabs, active}:Props) =>{
    return(
        <>
            
      <section id="home" className={active === 1 ? "section active" : "section"}>
        <div className="homecolor-box"></div>
        <div className="common_bg animate__animated animate__zoomIn">
          <div className="container m-auto">
            <div className="row align-items-center">
              <div className=" col-xl-4 col-lg-6 col-md-6 col-12">
                <div className="home-profile animate__animated animate__fadeInLeft animate__delay-1s">
                  <img src={profile} alt="home-profile"/>
                </div>
              </div>
              
              <div className="col-xl-7 col-lg-6 col-md-6 col-12">
                <div className="home-content">
                  <p className="common-desctiption animate__animated animate__fadeInDown animate__delay-1s">Get to know me</p>
                  <h1 className="common-title animate__animated animate__fadeInDown animate__delay-1s">Jignesh Chaudhari</h1>
                  <div className="animated-bar animate__animated animate__fadeInDown animate__delay-2s"></div>
                  <div className="animated-text animate__animated animate__fadeInDown animate__delay-2s">
                    <h2>Software Engineer</h2>
                    <h2>Web Developer</h2>
                    <h2>Blockchain Developer</h2>
                   
                  </div>
                  
                  <div className="fixed-block animate__animated animate__jackInTheBox animate__delay-2-5s">
                    <ul className="list-unstyled social-icons">
                      <li><a href="https://twitter.com/jignesh99789099" target={"_blank"}><i className="fab fa-twitter"></i></a></li>
                      <li><a href="https://www.facebook.com/missyouagain" target={"_blank"}><i className="fab fa-facebook-square "></i></a></li>
                      <li><a href="https://www.linkedin.com/in/jignesh-chaudhari-42b0b9b9" target={"_blank"}><i className="fab fa-linkedin "></i></a></li>
                      <li><a href="javascript:void(0)" target={"_blank"}><i className="fab fa-github-square "></i></a></li>
                      <li><a href="https://www.instagram.com/_jignesh_chaudhari_/" target={"_blank"}><i className="fab fa-instagram-square"></i></a></li>
                    </ul>
                  </div>
                  <p className="lorem-text animate__animated animate__zoomIn animate__delay-2-5s">I am ambitious and driven. I thrive on challenge and constantly set goals for myself, so I have something to strive toward. I'm not comfortable with settling, and I'm always looking for an opportunity to do better and achieve greatness
                  </p>
                  <div className="home-btn">
                    {/* <a href="#contact" data-section-index="1"
                      className="clickbtn hire-me animate__animated animate__fadeInTopLeft animate__delay-3s ">Hire me</a> */}
                    <a href="#about" data-section-index="1" onClick={ () => {setTabs(2)}}
                      className="clickbtn about-us animate__animated animate__fadeInTopRight animate__delay-3s">about me </a>
                  </div>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </section>
      
        </>
    );
}
export default HomeSection;