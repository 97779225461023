export interface Props{
    active : Number;
  }
const Contact = ({active} : Props) =>{
    return(<>
    
      <section id="contact" className={active === 3 ? "section active" : "section"}>
        <div className="homecolor-box"></div>
        <div className="common_bg animate__animated animate__zoomIn">
          <div className="container">
           
            <div className="contact-section">
             
              <div className="row">
                <div className="col-12">
                  <div className="section-title animate__animated animate__fadeInDown animate__delay-1s">
                    <p className="common-desctiption">Feel free to contact me anytimes</p>
                    <h1 className="common-title">my <span>contact</span></h1>
                    <div className="animated-bar"></div>
                  </div>
                </div>
              </div>
            
              <div className="contact-section">
                <div className="row">
                 
                  <div className="col-lg-7 col-12 ">
                    <form className="contact-form animate__animated animate__fadeInLeft animate__delay-2s"
                      id="contact-form">
                      <h4 className="content-title">contact me</h4>
                      <div className="row">
                        <div className="col-12 col-md-6 form-group"><input className="form-control" id="contact-name"
                            type="text" name="name" placeholder="Name" /></div>
                        <div className="col-12 col-md-6 form-group"><input className="form-control" id="contact-email"
                            type="email" name="email" placeholder="Email" /></div>
                        <div className="col-12 form-group"><input className="form-control" id="contact-subject" type="text"
                            name="subject" placeholder="Subject" /></div>
                        <div className="col-12 form-group form-message"><textarea className="form-control" id="contact-message"
                            name="message" placeholder="Message"  ></textarea></div>
                        <div className="col-12 mb-4 form-submit"><button className="clickbtn button-main button-scheme"
                            id="contact-submit" type="button">Send Message</button>
                        </div>
                      </div>
                    </form>
                  </div>
                  
                  <div className="col-lg-5 col-12 ">
                    <div className="contact-info animate__animated animate__fadeInRight animate__delay-3s">
                      <h4 className="content-title">Contact Info</h4>
                      <p className="info-description">Always available for freelance work if the right project comes along,
                        Feel free to contact me!</p>
                      <ul className="list-unstyled list-info" style={{display: "inline-grid"}}>
                        <li>
                          <div className="media d-flex align-items-center"><span className="info-icon"><i
                                className="fas fa-user-alt"></i></span>
                            <div className="media-body info-details">
                              <h6 className="info-type">Name</h6><span className="info-value">Jignesh Chaudhari</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="media d-flex align-items-center"><span className="info-icon"><i
                                className="fas fa-map-pin"></i></span>
                            <div className="media-body info-details">
                              <h6 className="info-type">Location</h6><span className="info-value">Sisor, Gujarat, India</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="media d-flex align-items-center"><span className="info-icon"><i
                                className="fas fa-phone-alt"></i></span>
                            <div className="media-body info-details">
                              <h6 className="info-type">Call Me</h6><span className="info-value"><a href="tel:+919825380434">+91 98253-80434</a></span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="media d-flex align-items-center"><span className="info-icon"><i
                                className="fas fa-envelope"></i></span>
                            <div className="media-body info-details">
                              <h6 className="info-type">Email Me</h6><span className="info-value"><a
                                  href="mailto:jignesh120r@gmail.com">jignesh120r@gmail.com</a></span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    
                    <div className="fixed-block d-flex animate__animated animate__jackInTheBox animate__delay-3s">
                      <ul className="list-unstyled social-icons p-3">
                      <li><a href="https://twitter.com/jignesh99789099" target={"_blank"}><i className="fab fa-twitter"></i></a></li>
                      <li><a href="https://www.facebook.com/missyouagain" target={"_blank"}><i className="fab fa-facebook-square "></i></a></li>
                      <li><a href="https://www.linkedin.com/in/jignesh-chaudhari-42b0b9b9" target={"_blank"}><i className="fab fa-linkedin "></i></a></li>
                      <li><a href="javascript:void(0)" target={"_blank"}><i className="fab fa-github-square "></i></a></li>
                      <li><a href="https://www.instagram.com/_jignesh_chaudhari_/" target={"_blank"}><i className="fab fa-instagram-square"></i></a></li>
                      </ul>
                    </div>
                  </div>
                  
                </div>
              </div>
              
             
              <div className="row justify-content-center animate__animated animate__fadeInUp animate__delay-5s">
                <div className="col-lg-6 text-center">
                  {/* <h5 className="footer">Copyright&copy; &#160;<script>
                      document.write(new Date().getFullYear())
                    </script><a> Avs Technolabs</a>&#160;&#160;<i
                      className="fas fa-heart animate__animated animate__pulse animate__faster animate__infinite	infinite"></i>&#160;&#160;All
                    rigths reserved</h5> */}
                </div>
              </div>
             
            </div>
            
          </div>
        </div>
      </section>
     
    </>)
};
export default Contact;
