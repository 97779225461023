import profile from "../assets/images/profile/profile2.jpg";
import Myskill from "./Myskill";
export interface Props{
  active : Number;
}
const About = ({active}:Props) =>{
    return(
        <>
            <section id="about" className={active === 2 ? "section active" : "section"}>
        <div className="homecolor-box"></div>
        <div className="common_bg animate__animated animate__fadeInDown">
          <div className="container">
            <div className="about-content">
              <div className="row ">
                <div className="col-12 ">
                  <div className="section-title animate__animated animate__fadeInDown animate__delay-1s">
                    <p className="common-desctiption">my intro</p>
                    <h1 className="common-title">About <span>Me</span></h1>
                    <div className="animated-bar"></div>
                  </div>
                </div>
              </div>
              <div className="row single-section">
                <div className="col-lg-4 profile-photo animate__animated animate__fadeInLeft animate__delay-2s ">
                  <div className="row">
                    <div className="col-12 d-block col-sm-none">
                      <img src={profile} className="img-fluid main-img-mobile" alt="my picture" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-sm-12">
                  <div className="row personal-info animate__animated animate__fadeInRight animate__delay-2s">

                    <div className="col-12">
                      <h5 className="personal-title">who am <span>i ?</span></h5>
                      <h2 className="personal-title">I'm Jignesh Chaudhari, a <span>Web
                          Developer</span> and <span>Blockchain Developer</span></h2>
                      <p className="info">I am ambitious and driven. I thrive on challenge and constantly set goals for myself, so I have something to strive toward. I'm not comfortable with settling, and I'm always looking for an opportunity to do better and achieve greatness.
                  </p>
                    </div>

                    <div className="row align-items-center">
                      <div className="col-lg-12">
                        <h2 className="personal-infotitle">personal <span>informations</span></h2>
                      </div>

                      <div className="col-lg-6">
                        <ul className="about-you ">
                          <li>
                            <span className="title">first name : </span>
                            <span className="value">Jignesh</span>
                          </li>
                          <li>
                            <span className="title">last name : </span>
                            <span className="value">Chaudhari</span>
                          </li>
                          <li>
                            <span className="title">address : </span>
                            <span className="value">Sisor, Tapi</span>
                          </li>
                          <li>
                            <span className="title">From : </span>
                            <span className="value">Gujarat, India</span>
                          </li>
                          <li>
                            <span className="title">Age : </span>
                            <span className="value">31 years</span>
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-6">
                        <ul className="about-you ">
                          <li>
                            <span className="title">E-mail : </span>
                            <span className="value"><a href="mailto:jignesh120r@gmail.com">jignesh120r@gmail.com</a></span>
                          </li>
                          <li>
                            <span className="title">Phone : </span>
                            <span className="value"><a href="tel:(+91)98253-80434">(+91) 98253-80434</a></span>
                          </li>
                          <li>
                            <span className="title">skype : </span>
                            <span className="value"> <a href="https://join.skype.com/invite/lFKL7kxyhG5Q" target={"_blank"}>https://join.skype.com/invite/lFKL7kxyhG5Q</a></span>
                          </li>
                          <li>
                            <span className="title">Langages :</span>
                            <span className="value">Gujarati, Hindi, English</span>
                          </li>
                          <li>
                            <span className="title">Freelance :</span>
                            <span className="value"> Available</span>
                          </li>
                        </ul>
                      </div>

                      <div className="col-lg-12">
                        <div className="About-btn">
                          <button id="b1" className="clickbtn download-cv">Download CV <i className="fa fa-download"
                              aria-hidden="true"></i></button>
                          <div className="col-lg-7 col-sm-6 col-12">
                            <ul className="list-unstyled social-icons">
                            <li><a href="https://twitter.com/jignesh99789099" target={"_blank"}><i className="fab fa-twitter"></i></a></li>
                            <li><a href="https://www.facebook.com/missyouagain" target={"_blank"}><i className="fab fa-facebook-square "></i></a></li>
                            <li><a href="https://www.linkedin.com/in/jignesh-chaudhari-42b0b9b9" target={"_blank"}><i className="fab fa-linkedin "></i></a></li>
                            <li><a href="javascript:void(0)" target={"_blank"}><i className="fab fa-github-square "></i></a></li>
                            <li><a href="https://www.instagram.com/_jignesh_chaudhari_/" target={"_blank"}><i className="fab fa-instagram-square"></i></a></li>
                    
                            </ul>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
             
              <div className="resume-section ">
                <div className="row">
                  <div className="col-12">
                    <div className="section-title animate__animated animate__fadeInUp animate__delay-3s">
                      <p className="common-desctiption">Check out my Resume</p>
                      <h1 className="common-title ">my <span>Resume</span></h1>
                      <div className="animated-bar "></div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-12 ">
                    <div className=" col-block education ">
                      <h2 className="about-subtitle">Education</h2>
                      <div className="resume-item"><span className="item-arrow"></span>
                        <h5 className="item-title">Bachelors's in Computer Application</h5><span
                          className="item-details">VNSGU / 2015 - 2017</span>
                        <p className="item-description">A Bachelor's in Computer Application equips students with fundamental knowledge and practical skills in software development, database management, and computer science principles.
                        </p>
                      </div>
                     
                      <div className="resume-item"><span className="item-arrow"></span>
                        <h5 className="item-title">Diploma In Computer Engineering</h5><span className="item-details">Gujarat Technological University
                          /
                          2009 - 2013</span>
                        <p className="item-description">A Diploma in Computer Engineering provides hands-on training in hardware, software, and networking, preparing students for careers in technology and IT-related fields.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12 ">
                    <div className=" col-block education">
                      <h2 className="about-subtitle">Experience</h2>
                      <div className="resume-item"><span className="item-arrow"></span>
                        <h5 className="item-title">Full Stack developer</h5><span className="item-details">Creadigol Solution / 2019 -
                          Current</span>
                        <p className="item-description">As a full-stack developer for our company, I architect and implement end-to-end solutions, leveraging my expertise in frontend and backend technologies to drive innovation.</p>
                      </div>
                      
                      <div className="resume-item"><span className="item-arrow"></span>
                        <h5 className="item-title">Full Stack Developer</h5><span className="item-details">Techybirds – Web solution / March 2018 -
                         August 2019</span>
                        <p className="item-description">As a full-stack developer for our company, I architect and implement end-to-end solutions, leveraging my expertise in frontend and backend technologies to drive innovation.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Myskill />
             <div className="services-section text-center ">
                <div className="row ">
                  <div className="col-12">
                    <div className="section-title  animate__animated animate__fadeInUp animate__slower animate__delay-3s ">
                      <p className="common-desctiption">Services i offer to my clients</p>
                      <h1 className="common-title">My <span>Services</span></h1>
                      <div className="animated-bar"></div>
                    </div>
                    <p className="service-text">With seven years of expertise, I architect and engineer end-to-end solutions,<br/> harmonizing front-end finesse and back-end robustness to create seamless digital experiences.
                    </p>
                  </div>
                </div>
                <div className="row animate__animated animate__zoomIn animate__slower animate__delay-3s">
                 <div
                    className="col-lg-3 col-sm-6 services-box animate__animated animate__zoomIn animate__slower animate__delay-3s">
                    <div className="service-item">
                      <i className="fas fa-laptop-code"></i>
                      <h4><span className="service-line">web design</span></h4>
                      <p>
                      crafting digital experiences, blending creativity, functionality, and usability to engage and delight users.
                      </p>
                    </div>
                  </div>
                
                  <div
                    className="col-lg-3 col-sm-6 services-box animate__animated animate__zoomIn animate__slower animate__delay-3s">
                    <div className="service-item">
                      <i className="fas fa-palette"></i>
                      <h4><span className="service-line">design</span></h4>
                      <p>
                      Design is the artful fusion of creativity and functionality, shaping experiences that inspire, inform, and captivate audiences.
                      </p>
                    </div>
                  </div>
                 
                  <div
                    className="col-lg-3 col-sm-6 services-box animate__animated animate__zoomIn animate__slower animate__delay-3s">
                    <div className="service-item">
                      <i className="fas fa-object-ungroup"></i>
                      <h4><span className="service-line">Product design</span></h4>
                      <p>
                      Product design is the strategic process of creating solutions that marry user needs with business objectives for seamless and delightful experiences.
                      </p>
                    </div>
                  </div>
                  
                  <div
                    className="col-lg-3 col-sm-6 services-box animate__animated animate__zoomIn animate__slower animate__delay-3s">
                    <div className="service-item">
                      <i className="far fa-object-ungroup"></i>
                      <h4><span className="service-line">UI/UX design</span></h4>
                      <p>
                      UI/UX design focuses on crafting intuitive interfaces and seamless user journeys to enhance engagement and satisfaction in digital experiences.
                      </p>
                    </div>
                  </div>
                  
                  <div
                    className="col-lg-3 col-sm-6 services-box animate__animated animate__zoomIn animate__slower animate__delay-3s">
                    <div className="service-item">
                      <i className="fas fa-radiation"></i>
                      <h4><span className="service-line">Animation</span></h4>
                      <p>
                      Animation breathes life into digital content, captivating audiences with movement, emotion, and storytelling, transcending static visuals to evoke immersive experiences.
                      </p>
                    </div>
                  </div>
                  
                  <div
                    className="col-lg-3 col-sm-6 services-box animate__animated animate__zoomIn animate__slower animate__delay-3s">
                    <div className="service-item">
                      <i className="fas fa-code"></i>
                      <h4><span className="service-line">Web devolopment</span></h4>
                      <p>
                      Web development is the art and science of building dynamic, interactive websites and applications that empower users and drive digital innovation.
                      </p>
                    </div>
                  </div>
                  
                  <div
                    className="col-lg-3 col-sm-6 services-box animate__animated animate__zoomIn animate__slower animate__delay-3s">
                    <div className="service-item">
                      <i className="fas fa-arrows-alt"></i>
                      <h4><span className="service-line">fully responsive</span></h4>
                      <p>
                      Fully responsive design ensures seamless user experiences across all devices, adapting fluidly to various screen sizes and orientations for optimal accessibility.
                      </p>
                    </div>
                  </div>
                  
                  <div
                    className="col-lg-3 col-sm-6 services-box animate__animated animate__zoomIn animate__slower animate__delay-3s">
                    <div className="service-item">
                      <i className="fas fa-dharmachakra"></i>
                      <h4><span className="service-line">Management</span></h4>
                      <p>
                      Management is the art of orchestrating resources, people, and processes to achieve goals efficiently, fostering growth, and driving organizational success.
                      </p>
                    </div>
                  </div>
                 
                </div>
              </div>
              
            
             
              <div className="funfacts-section">
                <div className="row">
                  <div className="col-12">
                    <div className="section-title">
                      <p className="common-desctiption">This are my strongest sides</p>
                      <h1 className="common-title">fun <span>facts</span></h1>
                      <div className="animated-bar"></div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3 col-md-6">
                    <div className="funfacts-box">
                      <h2 className="counter" data-to="10" data-time="10000">6</h2>
                      <p className="fun-text">years of <span>experience</span></p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="funfacts-box">
                      <h2 className="counter" data-to="499" data-time="100000">30</h2>
                      <p className="fun-text">happy Clients</p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="funfacts-box">
                      <h2 className="counter" data-to="101" data-time="100000">45</h2>
                      <p className="fun-text">completed projects</p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="funfacts-box">
                      <h2 className="counter" data-to="20" data-time="10000">100%</h2>
                      <p className="fun-text">Success</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

        </>
    );
}
export default About;