import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from "react";
import "../assets/css/all.css";
import "../assets/css/styles.css";
import "../assets/css/responsive.css";
import "../assets/css/animate.min.css"
import Header from "../components/Header";
import HomeSection from "../components/HomeSection";
import About from '../components/About';
import Contact from '../components/Contact';
const Home = () =>{
    const [active , setActive ] = useState(1);
   const setTabs = (num : any) =>{
    setActive(num);
   }
    return(
        <>
            <div className="gaspar" data-magic-cursor="show" data-color="crimson">
                {/* <div id="preloader">
                    <div className="loader_line"></div>
                </div> */}
                <Header setTabs ={setTabs} active={active}/>
                <div className="container ">
                    <HomeSection setTabs ={setTabs} active={active}/>
                    <About  active={active}/>
                    <Contact active={active}/>
                </div>
            </div>
        </>
    )
}
export default Home;