import "./Myskill.css";
const Myskill = () =>{
    return(<>
              <div className="skill-section">
                <div className="row">
                  <div className="col-12 ">
                    <div className="section-title animate__animated animate__fadeInUp animate__delay-3s">
                      <p className="common-desctiption">My level of knowledge in some tools</p>
                      <h1 className="common-title">my <span>skills</span></h1>
                      <div className="animated-bar"></div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 ">
                    
                    <div className="skill-bars">
                      <div className="bar">
                        <div className="info">
                          <span>React JS</span>
                        </div>
                        <div className="progress-line reactjs">
                          <span></span>
                        </div>
                      </div>
                      <div className="bar">
                        <div className="info">
                          <span>Node JS</span>
                        </div>
                        <div className="progress-line nodejs">
                          <span></span>
                        </div>
                      </div>
                      <div className="bar">
                        <div className="info">
                          <span>Mongodb</span>
                        </div>
                        <div className="progress-line mongodb">
                          <span></span>
                        </div>
                      </div>
                      <div className="bar">
                        <div className="info">
                          <span>Web3 JS</span>
                        </div>
                        <div className="progress-line web3js">
                          <span></span>
                        </div>
                      </div>
                      <div className="bar">
                        <div className="info">
                          <span>Rust</span>
                        </div>
                        <div className="progress-line rust">
                          <span></span>
                        </div>
                      </div>
                      <div className="bar">
                        <div className="info">
                          <span>Solidity</span>
                        </div>
                        <div className="progress-line solidity">
                          <span></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*--------------------------------------------------------------------*/}
                  <div className="col-lg-6 col-md-12 ">
                  
                    <div className="skill-bars">
                      <div className="bar">
                        <div className="info">
                          <span>PHP</span>
                        </div>
                        <div className="progress-line php">
                          <span></span>
                        </div>
                      </div>
                      <div className="bar">
                        <div className="info">
                          <span>HTML</span>
                        </div>
                        <div className="progress-line html">
                          <span></span>
                        </div>
                      </div>
                      <div className="bar">
                        <div className="info">
                          <span>CSS</span>
                        </div>
                        <div className="progress-line css">
                          <span></span>
                        </div>
                      </div>
                      <div className="bar">
                        <div className="info">
                          <span>Javascript</span>
                        </div>
                        <div className="progress-line Javascript">
                          <span></span>
                        </div>
                      </div>
                      <div className="bar">
                        <div className="info">
                          <span>Jquery</span>
                        </div>
                        <div className="progress-line jquery">
                          <span></span>
                        </div>
                      </div>
                      <div className="bar">
                        <div className="info">
                          <span>MySQL</span>
                        </div>
                        <div className="progress-line mysql">
                          <span></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             
        </>)
}
export default Myskill;