import { useEffect, useState } from "react";
export interface Props {
  
  setTabs?: any;
  active : Number;
}
const Header = ({setTabs, active} : Props) =>{
 
  
    return(
        <>
            <div className="header-holder services-section text-center animate__animated animate__zoomIn">
      <div className="navigation">
        <ul className="nav">
          <li className={active === 1? "list active" : "list"}>
            <a  href="#home" className={active === 1? "active" : ""} onClick={ () => {setTabs(1)}}>
              <span className="icon" >
                <i className="fas fa-house-user"></i>
              </span>
              <div className="social__tooltip social__tooltip-bottom">Home</div>
            </a>
          </li>
          <li className={active === 2? "list active" : "list"}>
            <a href="#about" className={active === 2? "active" : ""}  onClick={ () => {setTabs(2)}}>
              <span className="icon">
                <i className="fas fa-user-alt"></i>
              </span>
              <div className="social__tooltip social__tooltip-bottom">About</div>
            </a>
          </li>
          {/* <li className="list">
            <a href="#portfolio">
              <span className="icon">
                <i className="fas fa-briefcase"></i>
              </span>
              <div className="social__tooltip social__tooltip-bottom">Portfolio</div>
            </a>
          </li>
          <li className="list">
            <a href="#blog">
              <span className="icon">
                <i className="fas fa-blog"></i>
              </span>
              <div className="social__tooltip social__tooltip-bottom">Blog</div>
            </a>
          </li> */}
          <li className={active === 3? "list active" : "list"}>
            <a href="#contact" className={active === 3? "active" : ""} onClick={ () => {setTabs(3)}}>
              <span className="icon">
                <i className="fas fa-phone-alt"></i>
              </span>
              <div className="social__tooltip social__tooltip-bottom">Contact</div>
            </a>
          </li>
        </ul>
      </div>
    </div>
    
        </>
    );
}
export default Header;